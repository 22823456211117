import React from "react";
import { Link, useHistory } from "react-router-dom";

function LogOutBtn() {
  const history = useHistory();

  async function logOut() {
    localStorage.removeItem("token");
    window.location.reload();
    history.push("/login");
  }

  return (
    <Link
      onClick={logOut}
      to="/login"
      className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 mx-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        />
      </svg>{" "}
      Abmelden
    </Link>
  );
}

export default LogOutBtn;
