import React, { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import UserDataContext from "../../context/UserDataContext";
import {
  add,
  format,
  startOfWeek,
  getISOWeek,
  isAfter,
  endOfDay,
} from "date-fns";
import { de } from "date-fns/locale";
import { CSVLink } from "react-csv";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function CurrentOrders() {
  const currentDate = new Date();
  let startWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
  let filterDate = add(startWeek, { days: 3, hours: 12 });
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [orders, setOrders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [openEditOrderDate, setOpenEditOrderDate] = useState(false);
  const [commentData, setCommentData] = useState({
    id: "",
    comment: "",
  });
  const [editOrderDateData, setEditOrderDateData] = useState({
    id: "",
    orderDate: "",
  });
  const { userData } = useContext(UserDataContext);
  const [deleteOrder, setDeleteOrder] = useState({});
  const [checkOrder, setCheckOrder] = useState({});
  const token = localStorage.getItem("token");
  const [filters, setFilters] = useState({
    leistung: "",
    werk_halle: "",
    abteilung: "",
    erledigt: "",
    erledigt_am: "",
    zu_erledigen_am: "",
    manuelle_bestellung: "",
  });

  async function getOrders() {
    const orderRes = await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/filter`,
      { selectedYear, selectedMonth },
      { headers: { jwttoken: token } }
    );
    let sortedOrders = orderRes.data;
    sortedOrders.sort(function (a, b) {
      let keyA = new Date(a.orderDate);
      let keyB = new Date(b.orderDate);

      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    setOrders(sortedOrders);
    setFilteredOrders(sortedOrders);
  }

  const handleFilter = async (obj) => {
    switch (obj.filter) {
      case "LEISTUNG":
        let newLeistungFilter = filters;
        newLeistungFilter.leistung = obj.value;
        setFilters(newLeistungFilter);
        break;
      case "WERK_HALLE":
        let newWerkHalleFilter = filters;
        newWerkHalleFilter.werk_halle = obj.value;
        setFilters(newWerkHalleFilter);
        break;
      case "ABTEILUNG":
        let newAbteilungFilter = filters;
        newAbteilungFilter.abteilung = obj.value;
        setFilters(newAbteilungFilter);
        break;
      case "ERLEDIGT":
        let newErledigtFilter = filters;
        newErledigtFilter.erledigt = obj.value;
        setFilters(newErledigtFilter);
        break;
      case "MANUELLE_BESTELLUNG":
        let newManuelleBestellungFilter = filters;
        newManuelleBestellungFilter.manuelle_bestellung = obj.value;
        setFilters(newManuelleBestellungFilter);
        break;
      case "ZU_ERLEDIGEN_AM":
        let newOrder1Filter = filters;
        newOrder1Filter.zu_erledigen_am = obj.value;
        setFilters(newOrder1Filter);
        break;
      case "ERLEDIGT_AM":
        let newOrderDoneFilter = filters;
        newOrderDoneFilter.erledigt_am = obj.value;
        setFilters(newOrderDoneFilter);
        break;
      default:
        console.log("Wrong filter identifier.");
    }

    let newFilteredOrders = orders;

    if (filters.leistung) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        if (order.relatedMachinery) {
          return order.relatedMachinery.bezeichnung
            .toUpperCase()
            .includes(filters.leistung);
        } else if (order.manualOrder) {
          return order.manualOrder.bezeichnung
            .toUpperCase()
            .includes(filters.leistung);
        }
      });
    }

    if (filters.werk_halle) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        if (order.relatedMachinery) {
          return order.relatedMachinery.werk_halle
            .toUpperCase()
            .includes(filters.werk_halle);
        } else if (order.manualOrder) {
          return order.manualOrder.werk_halle
            .toUpperCase()
            .includes(filters.werk_halle);
        }
      });
    }

    if (filters.abteilung) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        if (order.relatedMachinery) {
          return order.relatedMachinery.abteilung
            .toUpperCase()
            .includes(filters.abteilung);
        } else if (order.manualOrder) {
          return order.manualOrder.abteilung
            .toUpperCase()
            .includes(filters.abteilung);
        }
      });
    }

    if (filters.manuelle_bestellung) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        if ("ja".includes(filters.manuelle_bestellung)) {
          return order.manualOrder;
        } else if ("nein".includes(filters.manuelle_bestellung)) {
          return !order.manualOrder;
        }
      });
    }

    if (filters.erledigt) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        if ("ja".includes(filters.erledigt)) {
          return order.status === true;
        } else if ("nein".includes(filters.erledigt)) {
          return order.status === false;
        } else if ("verspätet".includes(filters.erledigt)) {
          return (
            order.orderDoneDate &&
            isAfter(
              new Date(order.orderDoneDate),
              endOfDay(new Date(order.orderDate))
            )
          );
        }
      });
    }

    setFilteredOrders(newFilteredOrders);
  };

  const generateCsvData = () => {
    const csvData = [
      [
        "Leistung",
        "Werk & Halle",
        "Abteilung",
        "Erledigt?",
        "Manuelle Bestellung?",
        "Zu erledigen am",
        "Erledigt am",
        "Kommentar",
      ],
    ];

    filteredOrders.forEach((element) => {
      let newLine = [
        element.relatedMachinery
          ? element.relatedMachinery.bezeichnung
          : element.manualOrder.bezeichnung,
        element.relatedMachinery
          ? element.relatedMachinery.werk_halle
          : element.manualOrder.werk_halle,
        element.relatedMachinery
          ? element.relatedMachinery.abteilung
          : element.manualOrder.abteilung,
        element.status ? "ja" : "nein",
        element.manualOrder ? "ja" : "nein",
        element.orderDate
          ? format(Date.parse(element.orderDate), "EE", { locale: de }) +
            ", " +
            format(Date.parse(element.orderDate), "dd.MM.yyyy") +
            " (KW " +
            getISOWeek(new Date(element.orderDate)) +
            ")"
          : "",
        element.orderDoneDate
          ? format(Date.parse(element.orderDoneDate), "EE", { locale: de }) +
            ", " +
            format(Date.parse(element.orderDoneDate), "dd.MM.yyyy") +
            " (KW " +
            getISOWeek(new Date(element.orderDoneDate)) +
            ")"
          : "",
        element.comment,
      ];
      csvData.push(newLine);
    });
    setCsvData(csvData);
  };

  async function checkOrderCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/confirmManualOrder/${checkOrder.orderData._id}`,
      {},
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    getOrders();
    setOpenCheck(false);
  }

  async function deleteOrderCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/delete/${deleteOrder.orderData._id}`,
      deleteOrder,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenDelete(false);
    setFilteredOrders(
      filteredOrders.filter((order) => order._id !== deleteOrder.orderData._id)
    );
  }

  async function addCommentCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/update/${commentData.id}`,
      commentData,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenComment(false);
    getOrders();
  }

  async function editOrderDateCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/update/${editOrderDateData.id}`,
      editOrderDateData,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenEditOrderDate(false);
    getOrders();
  }

  useEffect(() => {
    getOrders();
  }, [selectedYear, selectedMonth]);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Historie
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Bitte beachten Sie, dass Leistungen immer Montags Nachts automatisch
            bestellt werden.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          <div className="col-span-6 sm:col-span-3 mr-3">
            <select
              name="kw"
              onChange={(e) => setSelectedMonth(e.target.value)}
              value={selectedMonth}
              className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="0">Januar</option>
              <option value="1">Februar</option>
              <option value="2">März</option>
              <option value="3">April</option>
              <option value="4">Mai</option>
              <option value="5">Juni</option>
              <option value="6">Juli</option>
              <option value="7">August</option>
              <option value="8">September</option>
              <option value="9">Oktober</option>
              <option value="10">November</option>
              <option value="11">Dezember</option>
            </select>
          </div>
          <div className="col-span-6 sm:col-span-3 mr-3">
            <select
              name="kw"
              onChange={(e) => setSelectedYear(e.target.value)}
              value={selectedYear}
              className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value={currentDate.getFullYear()}>
                {currentDate.getFullYear()}
              </option>
              <option value="2023">2023</option>
            </select>
          </div>
          <CSVLink
            onClick={generateCsvData}
            filename={"evobus-qrcodetool-export.csv"}
            data={csvData}
            className="ml-8 inline-flex items-center justify-center px-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            Export
          </CSVLink>
          <Link
            to="/historie/new"
            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            Hinzufügen
          </Link>
        </div>
      </div>
      <div className="bg-white w-full  shadow overflow-auto sm:rounded-lg flex justify-between my-5 border-b border-gray-200 shadow  w-full sm:rounded-lg">
        <table className="table-auto w-full overflow-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Leistung
                <input
                  type="text"
                  id="filter-auftragsnummer"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "LEISTUNG",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Werk & Halle
                <input
                  type="text"
                  id="filter-werk-halle"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "WERK_HALLE",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Abteilung
                <input
                  type="text"
                  id="filter-abteilung"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "ABTEILUNG",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Erledigt?
                <input
                  type="text"
                  id="filter-datum"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({ filter: "ERLEDIGT", value: e.target.value })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Manuelle Bestellung?
                <input
                  type="text"
                  id="filter-manual-order"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "MANUELLE_BESTELLUNG",
                      value: e.target.value,
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Zu erledigen am
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Erledigt am
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Kommentar
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Optionen
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredOrders.map((order, i) => {
              let rowColor = "";
              if (
                order.orderDoneDate &&
                isAfter(
                  new Date(order.orderDoneDate),
                  endOfDay(new Date(order.orderDate))
                )
              ) {
                console.log(order.orderDoneDate, order.orderDate);
                rowColor = "orange";
              } else if (order.status) {
                rowColor = "green";
              } else if (isAfter(currentDate, new Date(order.orderDate))) {
                rowColor = "red";
              }

              let status = "";
              if (
                order.orderDoneDate &&
                isAfter(
                  new Date(order.orderDoneDate),
                  endOfDay(new Date(order.orderDate))
                )
              ) {
                status = "verspätet";
              } else if (order.status) {
                status = "ja";
              } else {
                status = "nein";
              }

              let orderDoneDate = "";
              if (order.orderDoneDate) {
                orderDoneDate =
                  format(Date.parse(order.orderDoneDate), "EE", {
                    locale: de,
                  }) +
                  ", " +
                  format(Date.parse(order.orderDoneDate), "dd.MM.yyyy") +
                  " (KW " +
                  getISOWeek(new Date(order.orderDoneDate)) +
                  ")";
              }

              return (
                <tr key={order._id} className={"bg-" + rowColor + "-100"}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {order.relatedMachinery
                      ? order.relatedMachinery.bezeichnung
                      : order.manualOrder.bezeichnung}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {order.relatedMachinery
                      ? order.relatedMachinery.werk_halle
                      : order.manualOrder.werk_halle}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {order.relatedMachinery
                      ? order.relatedMachinery.abteilung
                      : order.manualOrder.abteilung}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{status}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {order.manualOrder ? "ja" : "nein"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {format(Date.parse(order.orderDate), "EE", { locale: de })},{" "}
                    {format(Date.parse(order.orderDate), "dd.MM.yyyy")} (KW{" "}
                    {getISOWeek(new Date(order.orderDate))})
                    {order.manualOrder && (
                      <a
                        href="#"
                        onClick={() => {
                          setEditOrderDateData({
                            id: order._id,
                          });
                          setOpenEditOrderDate(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 inline-block mx-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </a>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {orderDoneDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {order.comment}
                  </td>
                  <td>
                    {userData && userData.isAdmin && (
                      <>
                        {order.manualOrder && order && (
                          <a
                            href="#"
                            onClick={() => {
                              setCheckOrder({
                                orderData: order,
                              });
                              setOpenCheck(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 inline-block mx-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </a>
                        )}
                        {order.manualOrder && order.manualOrder.upload_url && (
                          <a
                            href={order.manualOrder.upload_url}
                            target="_blank"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 inline-block mx-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                              />
                            </svg>
                          </a>
                        )}
                        <a
                          href="#"
                          onClick={() => {
                            setCommentData({
                              id: order._id,
                              comment: order.comment,
                            });
                            setOpenComment(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 inline-block mx-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                            />
                          </svg>
                        </a>

                        <a
                          href="#"
                          onClick={() => {
                            setDeleteOrder({
                              orderData: order,
                            });
                            setOpenDelete(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 inline-block mx-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </a>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {openCheck && (
        <Transition.Root show={openCheck} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenCheck}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Bestellung erledigen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Beauftragung
                              als erledigt markieren möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={checkOrderCall}
                      >
                        Bestätigen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenCheck(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {openDelete && (
        <Transition.Root show={openDelete} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Bestellung stornieren
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Beauftragung
                              der ausgewählten Bestellung stornieren möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={deleteOrderCall}
                      >
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {openComment && (
        <Transition.Root show={openComment} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenComment}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Kommentar hinzufügen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Hier können Sie einen Kommentar zu einer
                              Bestellung hinzufügen oder bearbeiten.
                            </p>
                            <textarea
                              id="anmkerungen"
                              name="about"
                              rows={3}
                              onChange={(e) =>
                                setCommentData({
                                  ...commentData,
                                  comment: e.target.value,
                                })
                              }
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              value={commentData.comment}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={addCommentCall}
                      >
                        Speichern
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenComment(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {openEditOrderDate && (
        <Transition.Root show={openEditOrderDate} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setOpenEditOrderDate}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Ausführdatum ändern
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Hier können Sie das Ausführdatum der Bestellung
                              abändern.
                            </p>
                            <input
                              required
                              type="date"
                              min={format(new Date(), "yyyy-MM-dd")}
                              placeholder="Ausführdatum"
                              value={editOrderDateData.date}
                              onChange={(e) =>
                                setEditOrderDateData({
                                  ...editOrderDateData,
                                  orderDate: e.target.value,
                                })
                              }
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={editOrderDateCall}
                      >
                        Speichern
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenEditOrderDate(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
