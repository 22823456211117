import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Scan() {
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [payload, setPayload] = useState({});
  const [confirmationCode, setConfirmationCode] = useState("");
  const { id } = useParams();

  async function getMachinery() {
    const machineryRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/${id}`
    );
    setPayload(machineryRes.data);
  }

  async function submitConfirmationCode(e) {
    e.preventDefault();

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/orders/confirmOrder/${id}`,
        { confirmationCode }
      );
      setConfirmationCode("");
      setSuccessMessage(true);
    } catch (err) {
      setError(true);
      console.error(err);
    }
  }

  useEffect(() => {
    getMachinery();
    console.log(payload);
  }, []);

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h3 className="mt-6 text-center text-3xl font-bold text-gray-900">
              {payload.bezeichnung}
            </h3>
          </div>
          <form onSubmit={submitConfirmationCode} className="mt-8 space-y-6">
            <div className="col-span-6">
              <label className="block text-sm font-medium text-gray-700">
                Bestätigungscode
              </label>
              <input
                type="text"
                required
                placeholder="Bestätigungscode"
                onChange={(e) => setConfirmationCode(e.target.value)}
                value={confirmationCode}
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
              />
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Leistung erledigt
              </button>
              {successMessage && (
                <div
                  className="flex rounded-md items-center bg-green-500 text-white text-sm font-bold px-4 py-3 my-5"
                  role="alert"
                >
                  <svg
                    className="fill-current w-6 h-6 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <p>
                    Die Leistung wurde erfolgreich gespeichert. Sie können diese
                    Seite nun schließen.
                  </p>
                </div>
              )}
              {error && (
                <div
                  className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3 my-5"
                  role="alert"
                >
                  <svg
                    className="fill-current w-6 h-6 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <p>
                    Fehler bei der Übermittlung. Bitte überprüfen Sie den
                    Bestätigungscode oder wenden Sie sich an Ihren Vorgesetzten.
                  </p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Scan;
