import axios from "axios";
import React, { useEffect, useState, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import UserDataContext from "../../context/UserDataContext";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const token = localStorage.getItem("token");
  const { userData } = useContext(UserDataContext);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
  });

  async function getCustomers() {
    const userRes = await axios.get(`${process.env.REACT_APP_BACKEND}/auth/`, {
      headers: {
        jwttoken: token,
      },
    });
    setUsers(userRes.data);
    setFilteredUsers(userRes.data);
  }

  async function deleteUserCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/auth/delete/${deleteUser.userId}`,
      {},
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenDelete(false);
    setUsers(users.filter((user) => user._id !== deleteUser.userId));
  }

  const handleFilter = async (obj) => {
    switch (obj.filter) {
      case "NAME":
        let newNameFilter = filters;
        newNameFilter.name = obj.value;
        setFilters(newNameFilter);
        break;
    }

    let newFilteredUsers = users;
    if (filters.name) {
      newFilteredUsers = newFilteredUsers.filter(function (users) {
        return users.name.toUpperCase().includes(filters.name);
      });
    }

    setFilteredUsers(newFilteredUsers);
  };

  function renderUsers() {
    return filteredUsers.map((users, i) => {
      let createdAt = new Date(users.createdAt);
      let updatedAt = new Date(users.updatedAt);
      let createdAtFormatted = createdAt.toLocaleDateString("de-DE");
      let updatedAtFormatted = updatedAt.toLocaleDateString("de-DE");
      return (
        <tr key={users._id}>
          <td className="px-6 py-4 whitespace-nowrap">{users.name}</td>
          <td className="px-6 py-4 whitespace-nowrap">{users.email}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            {users.isAdmin ? "Administrator" : "Standardbenutzer"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">{createdAtFormatted}</td>
          <td className="px-6 py-4 whitespace-nowrap">{updatedAtFormatted}</td>
          <td className="px-6 py-4 inline-block whitespace-nowrap">
            <Link to={`/users/edit/${users._id}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </Link>
            <a
              href="#"
              onClick={() => {
                setDeleteUser({
                  name: users.name,
                  userId: users._id,
                });
                setOpenDelete(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline-block mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </a>
          </td>
        </tr>
      );
    });
  }

  useEffect(() => {
    getCustomers();
  }, [userData]);

  if (!userData || !userData.isAdmin) {
    return <div>404 - Fehlende Berechtigung!</div>;
  }

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Benutzerverwaltung
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Bearbeiten, anlegen oder löschen von Benutzerkonten.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          <Link
            to="/users/new"
            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
              />
            </svg>
            Benutzer hinzufügen
          </Link>
        </div>
      </div>

      <div className="bg-white w-full  shadow overflow-auto sm:rounded-lg flex justify-between my-5 border-b border-gray-200 shadow  w-full sm:rounded-lg">
        <table className="table-auto w-full overflow-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
                <input
                  type="text"
                  id="filter-kostenstelle"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "NAME",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Berechtigung
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Registriert seit
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Zuletzt bearbeitet
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Optionen
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {renderUsers()}
          </tbody>
        </table>
      </div>

      {openDelete && (
        <Transition.Root show={openDelete} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Benutzerkonto löschen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie das Benutzerkonto{" "}
                              <b>{deleteUser.name}</b> unwiderruflich löschen
                              möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={deleteUserCall}
                      >
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
