import axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import UserDataContext from "../../context/UserDataContext";
import { format } from "date-fns";

function NewOrder() {
  const [error, setError] = useState(false);
  const [payload, setPayload] = useState({
    bezeichnung: "",
    werk_halle: "Werk 5 - Halle 30",
    abteilung: "",
    orderDate: "",
    comment: "",
  });
  const [file, setFile] = useState(null);
  const token = localStorage.getItem("token");
  const { userData } = useContext(UserDataContext);
  console.log(userData)
  const history = useHistory();

  async function register(e) {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", document.getElementById("filePicker").files[0]);
      formData.append("bezeichnung", payload.bezeichnung);
      formData.append("werk_halle", payload.werk_halle);
      formData.append("abteilung", payload.abteilung);
      formData.append("orderDate", payload.orderDate);
      formData.append("comment", payload.comment);
      formData.append("user", userData.id);

      await axios.post(
        `${process.env.REACT_APP_BACKEND}/orders/new`,
        formData,
        {
          headers: {
            jwttoken: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      history.push("/historie/");
    } catch (err) {
      setError(true);
      console.error(err);
    }
  }

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Neue Bestellung hinzufügen
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Hier können Sie eine neue Leistung anlegen.
          </p>
        </div>
      </div>
      <div className="bg-white  overflow-hidden sm:rounded-lg flex justify-center my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg">
              <form onSubmit={register} enctype="multipart/form-data">
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <label className="block text-sm font-medium text-gray-700">
                          Bezeichnung
                        </label>
                        <input
                          type="text"
                          required
                          placeholder="Bezeichnung"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              bezeichnung: e.target.value,
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Werk & Halle
                        </label>
                        <select
                          name="werk-halle"
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              werk_halle: e.target.value,
                            });
                          }}
                          className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="Werk 5 - Halle 30">
                            Werk 5 - Halle 30
                          </option>
                          <option value="Werk 5 - Halle 40">
                            Werk 5 - Halle 40
                          </option>
                          <option value="Werk 5 - Halle 50">
                            Werk 5 - Halle 50
                          </option>
                          <option value="Werk 5 - Halle 60">
                            Werk 5 - Halle 60
                          </option>
                          <option value="Werk 5 - Halle 70">
                            Werk 5 - Halle 70
                          </option>
                          <option value="Werk 9 - Halle 20">
                            Werk 9 - Halle 20
                          </option>
                          <option value="Werk 9 - Halle 30">
                            Werk 9 - Halle 30
                          </option>
                          <option value="Werk 9 - Halle 50">
                            Werk 9 - Halle 50
                          </option>
                          <option value="Werk 4 - Halle 90">
                            Werk 4 - Halle 90
                          </option>
                          <option value="Werk 4 - Halle 92">
                            Werk 4 - Halle 92
                          </option>
                          <option value="Werk 4 - Halle 93">
                            Werk 4 - Halle 93
                          </option>
                          <option value="Werk 4 - Ausbildung">
                            Werk 4 - Ausbildung
                          </option>
                          <option value="Werk 8 - Halle 20">
                            Werk 8 - Halle 20
                          </option>
                          <option value="Werk 8 - Halle 30">
                            Werk 8 - Halle 30
                          </option>
                          <option value="Werk 8 - Halle 50">
                            Werk 8 - Halle 50
                          </option>
                          <option value="Werk 8.1 - Halle 90">
                            Werk 5.1 - Halle 90
                          </option>
                          <option value="Werk 8.1 - Halle 95">
                            Werk 5.1 - Halle 95
                          </option>
                          <option value="Werk 8.1 - Versuch">
                            Werk 5.1 - Versuch
                          </option>
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Abteilung
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Abteilung"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              abteilung: e.target.value,
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Ausführdatum
                        </label>
                        <input
                          required
                          type="date"
                          min={format(new Date(), "yyyy-MM-dd")}
                          placeholder="Ausführdatum"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              orderDate: e.target.value,
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Kommentar
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Kommentar"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              comment: e.target.value,
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>

                      <div class="col-span-6">
                        <label
                          for="cover-photo"
                          class="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Dateianhang
                        </label>
                        <div class="mt-2">
                          <input
                            type="file"
                            name="upload"
                            id="filePicker"
                            accept=".pdf, image/*"
                            onChange={(e) => setFile(e.target.files)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Speichern
                    </button>
                    {error && (
                      <div
                        className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3 my-5"
                        role="alert"
                      >
                        <svg
                          className="fill-current w-6 h-6 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>
                          Fehler bei der Übermittlung. Bitte überprüfen Sie ihre
                          Eingaben oder wenden Sie sich an den Support.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewOrder;
