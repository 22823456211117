import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import UserDataContext from "../../context/UserDataContext";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";

function EditMachinery() {
  const [error, setError] = useState(false);

  const [payload, setPayload] = useState({
    bezeichnung: "",
    turnustag: "",
    turnuswoche: "",
    date_chooseable: "",
  });

  const { getLoggedIn } = useContext(AuthContext);
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const { userData } = useContext(UserDataContext);

  async function getMachinery() {
    const machineryRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/${id}`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setPayload(machineryRes.data);
  }

  async function register(e) {
    e.preventDefault();

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/machinery/edit/${id}`,
        payload,
        {
          headers: {
            jwttoken: token,
          },
        }
      );
      await getLoggedIn();
      history.push("/equipment/");
    } catch (err) {
      setError(true);
      console.error(err);
    }
  }

  useEffect(() => {
    getMachinery();
  }, []);

  const downloadQRCode = () => {
    const qrcode = document.getElementById("qrcode");
    const pngUrl = qrcode
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qr-code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Anlage bearbeiten
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Hier können Sie eine bestehende Anlage bearbeiten.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          <Link
            onClick={downloadQRCode}
            to="#"
            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
              />
            </svg>
            QR-Code herunterladen
          </Link>
        </div>
      </div>
      <div className="bg-white overflow-hidden sm:rounded-lg flex justify-center my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg">
              <form onSubmit={register}>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <label className="block text-sm font-medium text-gray-700">
                          Bezeichnung
                        </label>
                        <input
                          type="text"
                          placeholder="Bezeichnung"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              bezeichnung: e.target.value,
                            })
                          }
                          value={payload.bezeichnung}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Turnus Woche
                        </label>
                        <select
                          name="kw"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              turnuswoche: e.target.value,
                            })
                          }
                          className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value={payload.turnuswoche}>
                            {payload.turnuswoche}-Wöchentlich
                          </option>
                          <option value="1">1-Wöchentlich</option>
                          <option value="2">2-Wöchentlich</option>
                          <option value="3">3-Wöchentlich</option>
                          <option value="4">4-Wöchentlich</option>
                          <option value="6">6-Wöchentlich</option>  
                          <option value="8">8-Wöchentlich</option>
                          <option value="13">13-Wöchentlich</option>
                          <option value="17">17-Wöchentlich</option>
                          <option value="26">26-Wöchentlich</option>
                          <option value="52">52-Wöchentlich</option>
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Turnus Tag
                        </label>
                        <select
                          name="kw"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              turnustag: e.target.value,
                            })
                          }
                          className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value={payload.turnustag}>
                            {payload.turnustag}
                          </option>
                          <option value="Montag">Montag</option>
                          <option value="Dienstag">Dienstag</option>
                          <option value="Mittwoch">Mittwoch</option>
                          <option value="Donnerstag">Donnerstag</option>
                          <option value="Freitag">Freitag</option>
                          <option value="Samstag">Samstag</option>
                          <option value="Sonntag">Sonntag</option>
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Ausführungsdatum frei wählbar? (Hinweis: Turnustag
                          wird ist dann immer automatisch Sonntag!)
                        </label>
                        <select
                          name="werk-halle"
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              date_chooseable: e.target.value,
                            });
                          }}
                          className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value={payload.date_chooseable}>
                            {payload.date_chooseable}
                          </option>
                          <option value="Nein">Nein</option>
                          <option value="Ja">Ja</option>
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Werk & Halle
                        </label>
                        <select
                          name="werk-halle"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              werk_halle: e.target.value,
                            })
                          }
                          className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value={payload.werk_halle}>
                            {payload.werk_halle}
                          </option>
                          <option value="Werk 5 - Halle 30">
                            Werk 5 - Halle 30
                          </option>
                          <option value="Werk 5 - Halle 40">
                            Werk 5 - Halle 40
                          </option>
                          <option value="Werk 5 - Halle 50">
                            Werk 5 - Halle 50
                          </option>
                          <option value="Werk 5 - Halle 60">
                            Werk 5 - Halle 60
                          </option>
                          <option value="Werk 5 - Halle 70">
                            Werk 5 - Halle 70
                          </option>
                          <option value="Werk 9 - Halle 20">
                            Werk 9 - Halle 20
                          </option>
                          <option value="Werk 9 - Halle 30">
                            Werk 9 - Halle 30
                          </option>
                          <option value="Werk 9 - Halle 50">
                            Werk 9 - Halle 50
                          </option>
                          <option value="Werk 4 - Halle 90">
                            Werk 4 - Halle 90
                          </option>
                          <option value="Werk 4 - Halle 92">
                            Werk 4 - Halle 92
                          </option>
                          <option value="Werk 4 - Halle 93">
                            Werk 4 - Halle 93
                          </option>
                          <option value="Werk 4 - Ausbildung">
                            Werk 4 - Ausbildung
                          </option>
                          <option value="Werk 8 - Halle 20">
                            Werk 8 - Halle 20
                          </option>
                          <option value="Werk 8 - Halle 30">
                            Werk 8 - Halle 30
                          </option>
                          <option value="Werk 8 - Halle 50">
                            Werk 8 - Halle 50
                          </option>
                          <option value="Werk 8.1 - Halle 90">
                            Werk 5.1 - Halle 90
                          </option>
                          <option value="Werk 8.1 - Halle 95">
                            Werk 5.1 - Halle 95
                          </option>
                          <option value="Werk 8.1 - Versuch">
                            Werk 5.1 - Versuch
                          </option>
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Abteilung
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Abteilung"
                          value={payload.abteilung}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              abteilung: e.target.value,
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Speichern
                    </button>
                    {error && (
                      <div
                        className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3 my-5"
                        role="alert"
                      >
                        <svg
                          className="fill-current w-6 h-6 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>
                          Fehler bei der Übermittlung. Bitte überprüfen Sie ihre
                          Eingaben oder wenden Sie sich an den Support.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div style={{ visibility: "hidden" }}>
              <QRCode
                id="qrcode"
                title="QR Code"
                size="1000"
                value={`https://daimler-bus.indutec-holding.de/scan/${id}`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditMachinery;
