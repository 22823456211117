import axios from "axios";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import UserDataContext from "../../context/UserDataContext";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { add, format, startOfWeek, getISOWeek, isAfter } from "date-fns";
import { de } from "date-fns/locale";

export default function MachineryList() {
  const [machinery, setMachinery] = useState([]);
  const [filteredMachinery, setFilteredMachinery] = useState([]);
  const { userData } = useContext(UserDataContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteMachinery, setDeleteMachinery] = useState({});
  const token = localStorage.getItem("token");
  const [filters, setFilters] = useState({
    bezeichnung: "",
    turnus: "",
    werk_halle_abteilung: "",
  });

  const handleFilter = async (obj) => {
    switch (obj.filter) {
      case "BEZEICHNUNG":
        let newBezeichnungFilter = filters;
        newBezeichnungFilter.bezeichnung = obj.value;
        setFilters(newBezeichnungFilter);
        break;
    }

    let newFilteredMachines = machinery;
    if (filters.bezeichnung) {
      newFilteredMachines = newFilteredMachines.filter(function (machine) {
        return machine.bezeichnung.toUpperCase().includes(filters.bezeichnung);
      });
    }

    setFilteredMachinery(newFilteredMachines);
  };

  async function getMachinery() {
    const userRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setMachinery(userRes.data);
    setFilteredMachinery(userRes.data);
  }

  async function deleteMachineryCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/machinery/delete/${deleteMachinery.machineryId}`,
      {},
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenDelete(false);
    setMachinery(
      machinery.filter(
        (machinery) => machinery._id !== deleteMachinery.machineryId
      )
    );
    setFilteredMachinery(
      machinery.filter(
        (machinery) => machinery._id !== deleteMachinery.machineryId
      )
    );
  }

  function renderMachinery() {
    return filteredMachinery.map((machine, i) => {
      let rowColor = "";
      // fällig < jetzt && (erledigt leer || erledigt < fällig)
      if (
        isAfter(new Date(), new Date(machine.last_time_ordered)) &&
        (!machine.last_time_done ||
          isAfter(
            new Date(machine.last_time_ordered),
            new Date(machine.last_time_done)
          ))
      ) {
        rowColor = "red";
      }

      return (
        <tr key={machine._id} className={"bg-" + rowColor + "-100"}>
          <td className="px-6 py-4 whitespace-nowrap">{machine.bezeichnung}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            {machine.date_chooseable == "Ja"
              ? "Tag frei wählbar"
              : machine.turnustag}
            , {machine.turnuswoche}-Wöchentlich
          </td>
          <td className="px-6 py-4 whitespace-nowrap">{machine.werk_halle}</td>
          <td className="px-6 py-4 whitespace-nowrap">{machine.abteilung}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            {machine.last_time_ordered &&
              format(Date.parse(machine.last_time_ordered), "EE", {
                locale: de,
              }) +
                ", " +
                format(Date.parse(machine.last_time_ordered), "dd.MM.yyyy") +
                " (KW" +
                getISOWeek(new Date(machine.last_time_ordered)) +
                ")"}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {machine.last_time_done &&
              format(Date.parse(machine.last_time_done), "EE", { locale: de }) +
                ", " +
                format(Date.parse(machine.last_time_done), "dd.MM.yyyy") +
                " (KW" +
                getISOWeek(new Date(machine.last_time_done)) +
                ")"}
          </td>
          <td className="px-6 py-4 inline-block whitespace-nowrap">
            {userData && userData.isAdmin && (
              <>
                <Link to={`/equipment/edit/${machine._id}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline-block mx-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </Link>
                <a
                  href="#"
                  onClick={() => {
                    setDeleteMachinery({
                      bezeichnung: machine.bezeichnung,
                      machineryId: machine._id,
                    });
                    setOpenDelete(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 inline-block mx-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </a>
              </>
            )}
          </td>
        </tr>
      );
    });
  }

  useEffect(() => {
    getMachinery();
  }, []);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Leistungen beauftragen
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Wählen Sie hier die Anlage aus, für die Sie eine neue Bestellung
            aufgeben möchten.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          {userData && userData.isAdmin && (
            <Link
              to="/equipment/new"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              Leistung hinzufügen
            </Link>
          )}
        </div>
      </div>
      <div className="bg-white w-full  shadow overflow-auto sm:rounded-lg flex justify-between my-5 border-b border-gray-200 shadow  w-full sm:rounded-lg">
        <table className="table-auto w-full overflow-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Bezeichnung
                <input
                  type="text"
                  id="filter-typ"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "BEZEICHNUNG",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Turnus
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Werk & Halle
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Abteilung
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Zuletzt fällig
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Zuletzt erledigt
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Optionen
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {renderMachinery()}
          </tbody>
        </table>
      </div>
      {openDelete && (
        <Transition.Root show={openDelete} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Leistung löschen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Leistung{" "}
                              <b>{deleteMachinery.bezeichnung}</b> inklusive
                              alle angelegten{" "}
                              <b>Leistungen und Bestellungen </b>
                              unwiderruflich löschen möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={deleteMachineryCall}
                      >
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
