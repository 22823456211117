import axios from "axios";
import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { LockClosedIcon } from "@heroicons/react/solid";
import UserDataContext from "../../context/UserDataContext";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

export default function Login() {
  const [email, setEmail] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [openForgotPW, setOpenForgotPW] = useState(false);
  const { getLoggedIn } = useContext(AuthContext);
  const { getUserData } = useContext(UserDataContext);
  const history = useHistory();

  async function login(e) {
    e.preventDefault();

    try {
      const loginData = {
        email,
        password,
      };

      const loginRes = await axios.post(
        `${process.env.REACT_APP_BACKEND}/auth/login`,
        loginData
      );
      localStorage.setItem("token", loginRes.data.token);
      await getLoggedIn();
      await getUserData();

      history.push("/");
    } catch (err) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      console.error(err);
    }
  }

  async function resetPW(e) {
    e.preventDefault();

    try {
      const loginData = {
        resetEmail,
      };

      const loginRes = await axios.post(
        `${process.env.REACT_APP_BACKEND}/auth/reset-password`,
        loginData
      );
      setOpenForgotPW(false);
      history.push("/login");
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Anmeldung
            </h2>
            <h3 className="mt-6 text-center text-3xl font-bold text-gray-900">
              INDUTEC Service Manager
            </h3>
            <p className="mt-2 text-center text-sm text-gray-600">
              Falls Sie kein eigenes Benutzerkonto haben oder Ihr Passwort
              vergessen haben wenden Sie sich bitte an Ihren Ansprechpartner.
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={login}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email Adresse
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email Adresse"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Passwort
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Passwort"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Anmelden
              </button>
            </div>
          </form>
          <div className="text-center" onClick={() => setOpenForgotPW(true)}>
            <a href="#">Passwort vergessen?</a>
          </div>
          {error && (
            <div
              className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3"
              role="alert"
            >
              <svg
                className="fill-current w-6 h-6 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
              </svg>
              <p>
                Fehler bei der Anmeldung. Bitte überprüfen Sie ihre Eingaben
                oder wenden Sie sich an den Support.
              </p>
            </div>
          )}
        </div>
      </div>
      {openForgotPW && (
        <Transition.Root show={openForgotPW} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenForgotPW}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Passwort zurücksetzen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Bitte geben Sie Ihre E-Mail Adresse an. Falls es
                              ein Benutzerkonto mit der angegeben E-Mail Adresse
                              gibt senden wir Ihnen eine E-Mail mit einem neuen
                              Passwort zu.
                              <form
                                className="mt-8 space-y-6"
                                onSubmit={resetPW}
                              >
                                <div className="rounded-md shadow-sm ">
                                  <div>
                                    <label
                                      htmlFor="email-address"
                                      className="sr-only"
                                    >
                                      Email Adresse
                                    </label>
                                    <input
                                      id="email-address"
                                      name="email"
                                      type="email"
                                      autoComplete="email"
                                      required
                                      onChange={(e) =>
                                        setResetEmail(e.target.value)
                                      }
                                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                      placeholder="Email Adresse"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                      <LockClosedIcon
                                        className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    Absenden
                                  </button>
                                </div>
                              </form>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
