import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/auth/Login";
import Users from "./components/users/Users";
import NewUser from "./components/users/NewUser";
import EditUser from "./components/users/EditUser";
import Navbar from "./components/layout/Navbar";
import AuthContext from "./context/AuthContext";
import MachineryList from "./components/machinery/MachineryList";
import NewMachinery from "./components/machinery/NewMachinery";
import CurrentOrders from "./components/orders/CurrentOrders";
import EditMachinery from "./components/machinery/EditMachinery";
import Start from "./components/layout/Start";
import Scan from "./components/scan/Scan";
import NewOrder from "./components/orders/newOrder";

function Router() {
  const { loggedIn } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Navbar />
      <main>
        <div className="max-w-full mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <Switch>
              {window.location.href.includes("scan") && (
                <>
                  <Route path="/scan/:id">
                    <Scan />
                  </Route>
                </>
              )}

              {loggedIn === false && !window.location.href.includes("scan") && (
                <>
                  <Redirect to="/login" />
                  <Route path="/login">
                    <Login />
                  </Route>
                </>
              )}
              {loggedIn === true && (
                <>
                  <Route exact path="/users">
                    <Users />
                  </Route>
                  <Route exact path="/users/new">
                    <NewUser />
                  </Route>
                  <Route path="/users/edit/:id">
                    <EditUser />
                  </Route>
                  <Route exact path="/historie">
                    <CurrentOrders />
                  </Route>
                  <Route exact path="/historie/new">
                    <NewOrder />
                  </Route>
                  <Route exact path="/equipment">
                    <MachineryList />
                  </Route>
                  <Route exact path="/equipment/new">
                    <NewMachinery />
                  </Route>
                  <Route exact path="/equipment/edit/:id">
                    <EditMachinery />
                  </Route>
                  <Route exact path="/">
                    <Start />
                  </Route>
                </>
              )}
            </Switch>
          </div>
        </div>
      </main>
    </BrowserRouter>
  );
}

export default Router;
